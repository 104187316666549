import {ECOM_MEMBERS_AREA_DEFAULT_PAGES, ecomAppDefID} from '../constants';
import {addApplications, installMembersArea, registerMembersAreaApps} from '@wix/members-area-integration-kit';

export async function addPage(sdk: IEditorSdk, pageId: string, shouldNavigate: boolean) {
  const productPageData = await sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    managingAppDefId: ecomAppDefID,
    componentType: sdk.document.tpa.TPAComponentType.Page,
    page: {
      pageId,
      shouldNavigate,
    },
  });

  return sdk.document.pages.setState('', {
    state: {
      [pageId]: [{id: productPageData.pageRef.id}],
    },
  });
}

export function addWidget(sdk: IEditorSdk, widgetId: string, addToAllPages: boolean): Promise<string> {
  return sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    componentType: sdk.tpa.TPAComponentType.Widget,
    widget: {
      widgetId,
      allPages: addToAllPages,
    },
  });
}

export async function addMembersArea(editorType: string, firstInstall: boolean, biData?: {origin?: string}) {
  await registerMembersAreaApps(ECOM_MEMBERS_AREA_DEFAULT_PAGES);
  if (!firstInstall || editorType === 'ADI') {
    return Promise.resolve();
  }

  await installMembersArea();
  await addApplications(ECOM_MEMBERS_AREA_DEFAULT_PAGES, biData && {biData});
}
